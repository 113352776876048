import * as Immutable from 'immutable';
import { applicationState } from 'core/state/applicationState';
import { follow } from 'core/backend/hateoas';
import { doRequest, RequestMethod } from 'core/backend/doRequest';
import { get, isValueNotEmpty, RESOLVE_OBJECT } from 'core/util/lang';
import logger from 'core/logging/logger';
import _ from 'lodash';
import { changeLocale } from 'core/i18n/translate';
import urlUtil from 'core/backend/url';

const sessionLogger = logger('session');
const sessionReference = applicationState.reference('session');
export enum FunctionPermissionLevel {
  ENABLED = 2,
  DISABLED = 1,
  INVISIBLE = 0,
}
export const FUNCTION_PERMISSION_LEVELS = 'functionPermissionLevels';

class Session {
  //noinspection JSMethodCanBeStatic
  resetSession() {
    sessionReference
      .cursor()
      .delete('id')
      .delete('user')
      .delete('theme')
      .delete('personId')
      .delete('person')
      .delete(FUNCTION_PERMISSION_LEVELS)
      .delete('externalLinks')
      .delete('jobProfileFunctions');
  }

  //noinspection JSMethodCanBeStatic
  set(receivedSessionInformation) {
    sessionReference
      .cursor()
      .set('id', receivedSessionInformation.id)
      .set('user', Immutable.fromJS(receivedSessionInformation.user))
      .set('theme', receivedSessionInformation.theme)
      .set('personId', receivedSessionInformation.personId)
      .set('person', receivedSessionInformation.person)
      .set(
        FUNCTION_PERMISSION_LEVELS,
        Immutable.fromJS(get(receivedSessionInformation, 'options.' + FUNCTION_PERMISSION_LEVELS))
      )
      .set('sitemap', Immutable.fromJS(get(receivedSessionInformation, 'options.sitemap')))
      .set('externalLinks', Immutable.fromJS(get(receivedSessionInformation, 'options.externalLinks')))
      .set('jobProfileFunctions', Immutable.fromJS(get(receivedSessionInformation, 'options.jobProfileFunctions')));
  }

  setEmbeddedInformation(embeddedInformation) {
    this.set(embeddedInformation);
    return this.setUILocale(embeddedInformation);
  }

  //noinspection JSMethodCanBeStatic
  isAuthenticated() {
    return get(sessionReference, 'user.dummyUser') === false;
  }

  hasUserImage() {
    if (this.isAuthenticated()) {
      return (
        isValueNotEmpty(follow('picture', get(sessionReference, 'user'))) ||
        isValueNotEmpty(follow('thumbnail', get(sessionReference, 'user')))
      );
    } else {
      return false;
    }
  }

  hasEmployee() {
    return get(sessionReference, 'user.employee') === true;
  }

  navigateToLogin() {
    const loginURLTemplate =
      get(applicationState, 'configuration.loginURL') || '{context}/common/login.do?redirectUrl={redirectUrl}';

    window.location.href = urlUtil.build(loginURLTemplate, {
      redirectUrl: window.location.href,
    });
  }

  reLogin() {
    return doRequest('{context}/{api}/logout', {
      method: RequestMethod.POST,
    })
      .then((result) => {
        window.location.reload();
        return result;
      })
      .catch((error) => {
        sessionLogger.error('logout failed', error);
        return error;
      });
  }

  reloadBecauseOfInvalidSession = () => {
    if (this.isAuthenticated()) {
      // simple reload -> no additional login
      window.location.reload();
    } else {
      this.navigateToLogin();
    }
  };

  //noinspection JSMethodCanBeStatic
  isEnabled(permissionToCheck) {
    return (
      (get(sessionReference.cursor([FUNCTION_PERMISSION_LEVELS]), permissionToCheck) ||
        FunctionPermissionLevel.INVISIBLE) >= FunctionPermissionLevel.ENABLED
    );
  }

  isVisible(permissionToCheck) {
    return (
      (get(sessionReference.cursor([FUNCTION_PERMISSION_LEVELS]), permissionToCheck) ||
        FunctionPermissionLevel.INVISIBLE) >= FunctionPermissionLevel.DISABLED
    );
  }

  getSitemap() {
    return get(sessionReference, 'sitemap', RESOLVE_OBJECT) || null;
  }

  getExternalLinks() {
    return get(sessionReference, 'externalLinks', RESOLVE_OBJECT) || null;
  }

  getJobProfileFunctions() {
    return get(sessionReference, 'jobProfileFunctions', RESOLVE_OBJECT) || null;
  }

  /***
   *
   * @param {string[]} permissionsToCheck
   */
  allPermissionsVisible(permissionsToCheck) {
    return _.every(permissionsToCheck, this.isVisible);
  }

  /***
   *
   * @param {string[]} permissionsToCheck
   */
  isAnyPermissionEnabled(permissionsToCheck) {
    return _.some(permissionsToCheck, this.isEnabled);
  }

  //noinspection JSMethodCanBeStatic
  getUserCursor() {
    return sessionReference.cursor('user');
  }

  getTheme() {
    return get(sessionReference, 'theme');
  }

  getPersonId() {
    return get(sessionReference, 'personId');
  }

  getPerson() {
    return get(sessionReference, 'person');
  }

  setUILocale(receivedSessionInformation) {
    const userUILocale = get(receivedSessionInformation, 'user.currentLocale');
    sessionLogger.info('set current locale to ui locale of session', userUILocale);
    return changeLocale(userUILocale, false);
  }

  /**
   * logout current session and trigger reload
   * used for applications which don't need an authenticated session to work
   */
  logout(skipReload) {
    return doRequest('{context}/{api}/logout', {
      method: RequestMethod.POST,
    })
      .then((result) => {
        if (skipReload === true) {
          this.resetSession();
        } else {
          // simply reload to make sure no cached data still floating around somewhere
          window.location.reload();
        }

        return result;
      })
      .catch((error) => {
        sessionLogger.error('logout failed', error);
        return error;
      });
  }

  /**
   * simply navigate to logout endpoint from engage
   * used for applications which always need an authenticated session to work
   */
  redirectLogout() {
    window.location.href = urlUtil.build('{context}/common/logout.do');
  }

  check() {
    console.log('SESSION CHECK', get(applicationState, 'configuration.application'));

    return doRequest('{context}/{api}/{version}/session', {
      data: { application: get(applicationState, 'configuration.application') },
    })
      .then((result) => {
        const receivedSessionInformation = result.data;
        this.set(receivedSessionInformation);
        this.setUILocale(receivedSessionInformation);

        return result;
      })
      .catch((error) => {
        this.resetSession();

        let resultPromise;
        if (_.get(error, 'response.status') === 401) {
          // 401 is a positive return state so resolve!
          resultPromise = Promise.resolve(error);
        } else {
          resultPromise = Promise.reject(error);
        }
        return resultPromise;
      });
  }
}

export default new Session();
