import moment from 'moment';
import 'moment-jdateformatparser';
import _ from 'lodash';
import Immutable from 'immutable';
import { get, isDefined, isNotDefined, isValueEmpty, isValueNotEmpty, RESOLVE_OBJECT, set } from 'core/util/lang';
import { resolveData, settleAll } from 'core/util/promises';
import { doRequest } from 'core/backend/doRequest';
import url from 'core/backend/url';
import createLogger from 'core/logging/logger';
import MessageFormat from 'core/i18n/internal/messageformat';
import NumberFormat from 'core/i18n/internal/number';
import { applicationState } from 'core/state/applicationState';

const logger = createLogger('i18n');
let fetchHistory = Immutable.Map();
let textModuleRequestCache = {};

function fetchFormatStringsPredicate(fetchPath) {
  return fetchPath === 'formatstrings';
}

// only use for testing
export function resetFetchHistory() {
  fetchHistory = Immutable.Map();
  textModuleRequestCache = {};
}

export function fetchFormatStrings() {
  let resultPromise;
  const currentLocale = getCurrentLocale();

  if (!fetchHistory.has(currentLocale) || !fetchHistory.get(currentLocale).keySeq().find(fetchFormatStringsPredicate)) {
    // fetch
    const i18nCacheKey = applicationState.cursor().getIn(['cacheKeys', 'i18n', 'key']);

    if (isDefined(i18nCacheKey)) {
      fetchHistory = set(fetchHistory, [currentLocale, 'formatstrings'], 'formatstrings');

      resultPromise = doRequest('{context}/{api}/{version}/i18n/{cacheKey}/{path}/{currentLocale}', {
        urlVariables: {
          cacheKey: i18nCacheKey,
          path: 'formatstrings',
          currentLocale,
        },
      })
        .then((formatStringsResult) => {
          applicationState.cursor().mergeDeepIn(
            ['i18n', currentLocale, 'formatStrings'],
            Immutable.fromJS(
              _.transform(formatStringsResult.data, (transformed, value, key) => {
                transformed[key] = get(value, '_text');
              })
            )
          );
        })
        .catch((error) => {
          logger.warn('failed to retrieve formatstrings for locale', currentLocale, error);
        });
    } else {
      resultPromise = Promise.resolve();
    }
  } else {
    resultPromise = Promise.resolve();
  }

  return resultPromise;
}

export function fetch(path, currentLocale = get(applicationState, ['i18n', 'currentLocale'])) {
  let resultPromise;

  const pathPredicate = (fetchPath) => {
    return _.startsWith(path, fetchPath);
  };

  if (!fetchHistory.has(currentLocale) || !fetchHistory.get(currentLocale).keySeq().find(pathPredicate)) {
    // fetch
    const i18nCacheKey = get(applicationState, ['cacheKeys', 'i18n', 'key']);

    if (isDefined(i18nCacheKey)) {
      fetchHistory = set(fetchHistory, [currentLocale, path], path);

      resultPromise = doRequest('{context}/{api}/{version}/i18n/{cacheKey}/{path}/{currentLocale}', {
        urlVariables: {
          cacheKey: i18nCacheKey,
          path,
          currentLocale,
        },
      })
        .then((result) => {
          applicationState.cursor().mergeDeepIn(['i18n', currentLocale], Immutable.fromJS(result.data));
        })
        .catch((error) => {
          logger.warn(`failed to retrieve ${path} for locale ${currentLocale}`, error);
        });
    } else {
      resultPromise = Promise.resolve();
    }
  } else {
    resultPromise = Promise.resolve();
  }

  return resultPromise;
}

export function changeLocale(newLocale, shouldChangeBackendLocale = true) {
  // TODO: sanitychecks

  const changeBackendLocale = (newLocale) => {
    return () => {
      if (shouldChangeBackendLocale === true) {
        return doRequest('{context}/{api}/{version}/session/changelocale?locale={newLocale}', {
          method: 'POST',
          urlVariables: {
            newLocale,
          },
        }).tap(() => {
          logger.info('changed backend locale to ', newLocale);
        });
      } else {
        return Promise.resolve();
      }
    };
  };

  return changeBackendLocale(newLocale)()
    .then(() => fetchTranslations(newLocale))
    .then(() => changeFrontendLocale(newLocale))
    .then(() => fetchFormatStrings());
}

export function changeFrontendLocale(newLocale) {
  applicationState.cursor().setIn(['i18n', 'currentLocale'], newLocale);
  moment.locale(newLocale);
}

export function fetchTranslations(locale) {
  logger.info('prefill i18n cache');
  return settleAll((get(applicationState, 'i18n.fetchKeys', RESOLVE_OBJECT) || []).map((key) => fetch(key, locale)));
}

function translateLabelWithMessageFormat(path, locale, label, data) {
  let text;
  try {
    const compiledMessage = new MessageFormat(locale).compile(label);
    text = compiledMessage(data);
  } catch (err) {
    text = label;

    logger.error(
      `compilation of label [${path}] failed
       text: ${label}
      `,
      err
    );
  }
  return text;
}

function translateLabelCacheKey(path, locale, label, data) {
  return `[${locale}][${label}]${JSON.stringify(data)}`;
}

const translateLabel = _.memoize(translateLabelWithMessageFormat, translateLabelCacheKey);

function getCurrentTranslation(path) {
  const i18nConfig = applicationState.cursor(['i18n']);
  const currentLocale = get(i18nConfig, 'currentLocale');
  const translations = i18nConfig.cursor(currentLocale);

  const keyPath = path.split('.').concat('_text');
  return get(translations, keyPath);
}

export function translateWithFallback(path, fallbackPath, data) {
  let result = '';

  if (isValueNotEmpty(path) && isValueNotEmpty(fallbackPath)) {
    const i18nConfig = applicationState.cursor(['i18n']);
    const currentLocale = get(i18nConfig, 'currentLocale');

    let currentTranslation = getCurrentTranslation(path);

    if (isNotDefined(currentTranslation)) {
      currentTranslation = getCurrentTranslation(fallbackPath);
    }

    if (isNotDefined(currentTranslation)) {
      fetch(path);
      fetch(fallbackPath);
    } else {
      result = translateLabel(path, currentLocale, currentTranslation, data);
    }
  }

  return result;
}

export function translate(path, data) {
  let result = '';

  if (!_.isEmpty(path)) {
    const i18nConfig = applicationState.cursor(['i18n']);
    const currentLocale = get(i18nConfig, 'currentLocale');

    const currentTranslation = getCurrentTranslation(path);

    if (_.isUndefined(currentTranslation)) {
      fetch(path);
    } else {
      result = translateLabel(path, currentLocale, currentTranslation, data);
    }
  }

  return result;
}

export function formatDate(date, pattern) {
  const i18nConfig = applicationState.cursor(['i18n']);
  const currentLocale = get(i18nConfig, 'currentLocale');
  const localePatterns = get(i18nConfig, [currentLocale, 'formatStrings']);
  let result;

  if (isValueNotEmpty(date) && isDefined(moment.localeData(currentLocale))) {
    const wrapper = moment(date, [moment.ISO_8601, 'HH:mm:ss.SSS']);

    if (wrapper.isValid()) {
      const formatPattern = get(localePatterns, pattern) || pattern || get(localePatterns, 'shortDateTimePattern');
      wrapper.locale(currentLocale);

      result = wrapper.formatWithJDF(formatPattern);
    } else {
      result = '';
    }
  } else {
    // ignore because we can't format without locale information
    result = '';
  }

  return result;
}

function parseDateString(dateAsString, currentLocale, javaPattern, strict) {
  let pattern;
  if (javaPattern) {
    pattern = moment().toMomentFormatString(javaPattern);
  }

  return moment(dateAsString, pattern, currentLocale, strict);
}

export function parseDate(dateAsString, pattern, strict = false) {
  const i18nConfig = applicationState.cursor(['i18n']);
  const currentLocale = get(i18nConfig, 'currentLocale');
  const localePatterns = get(i18nConfig, [currentLocale, 'formatStrings']);
  let result;

  if (isDefined(moment.localeData(currentLocale))) {
    if (pattern) {
      // parse with pattern
      const formatPattern = get(localePatterns, pattern) || pattern || get(localePatterns, 'dateTime');
      result = parseDateString(dateAsString, currentLocale, formatPattern, strict);
    } else {
      // 1. try parse without pattern
      result = parseDateString(dateAsString, currentLocale, strict);

      if (!result.isValid()) {
        result = parseDateString(dateAsString, currentLocale, localePatterns.get('dateTime'));
      }
    }

    // if parsed date is invalid return null
    if (!result.isValid()) {
      result = null;
    } else {
      result = result.toDate();
    }
  } else {
    // ignore because we can't format without locale information
    result = null;
  }

  return result;
}

export function formatCurrency(number, pattern, currency) {
  const i18nConfig = applicationState.cursor(['i18n']);
  const currentLocale = get(i18nConfig, 'currentLocale');
  const formatPattern = getCurrencyPattern(pattern);
  let result;

  if (_.has(NumberFormat.locale, currentLocale)) {
    result = new NumberFormat(currentLocale, formatPattern, currency).format(number);
    if (_.startsWith(result, pattern)) {
      logger.warn(`invalid/unknown pattern '${pattern}' to format currency`);
      result = new NumberFormat(currentLocale, getCurrencyPattern()).format(number);
    }
  } else {
    // ignore because we can't format without locale information
    result = '';
  }

  return result;
}

export function getCurrentLocale() {
  const i18nConfig = applicationState.cursor(['i18n']);
  return get(i18nConfig, 'currentLocale');
}

export function getCurrentLocaleAsLanguageTag() {
  return _.replace(getCurrentLocale(), '_', '-');
}

export function getLocaleInformation(name) {
  const i18nConfig = applicationState.cursor(['i18n']);
  const currentLocale = getCurrentLocale();
  const path = [currentLocale, 'detailedLocaleInformation'].concat(_.split(name, '.'));
  return get(i18nConfig, path);
}

function getNumberPattern(pattern) {
  const i18nConfig = applicationState.cursor(['i18n']);
  const currentLocale = getCurrentLocale();
  const localePatterns = get(i18nConfig, [currentLocale, 'formatStrings']);
  return get(localePatterns, pattern) || pattern || get(localePatterns, 'decimalPattern');
}

function getCurrencyPattern(pattern) {
  const i18nConfig = applicationState.cursor(['i18n']);
  const currentLocale = getCurrentLocale();
  const localePatterns = get(i18nConfig, [currentLocale, 'formatStrings']);

  return get(localePatterns, pattern) || pattern || get(localePatterns, 'currencyPattern');
}

function getCurrencySymbol() {
  return getLocaleInformation('currencySign');
}

export function getDecimalSeparator() {
  return getLocaleInformation('decimalSeperator');
}

export function getGroupSeparator() {
  return getLocaleInformation('groupSeperator');
}

export function formatNumber(number, pattern) {
  const currentLocale = getCurrentLocale();
  const formatPattern = getNumberPattern(pattern);
  let result;

  if (_.has(NumberFormat.locale, currentLocale)) {
    result = new NumberFormat(currentLocale, formatPattern).format(number);
    if (_.startsWith(result, pattern)) {
      logger.warn(`invalid/unknown pattern '${pattern}' to format number`);
      result = new NumberFormat(currentLocale, getNumberPattern()).format(number);
    }
  } else {
    // ignore because we can't format without locale information
    result = '';
  }

  return result;
}

export function formatNumberWithPrefixedUnit(size, unit, precision) {
  const fraction = 1024;
  const prefixes = ['', 'k', 'M', 'G', 'T', 'P'];
  let factor = 0;
  let convertedSize = size;
  while (convertedSize >= fraction && factor < prefixes.length - 1) {
    convertedSize = convertedSize / fraction;
    factor++;
  }
  if (isDefined(precision)) {
    convertedSize = convertedSize.toFixed(precision);
  }
  let formattedNumber = formatNumber(convertedSize);
  if (isDefined(unit)) {
    formattedNumber = formattedNumber + ' ' + prefixes[factor] + unit;
  }
  return formattedNumber;
}

export function parseNumber(number, pattern) {
  const currentLocale = getCurrentLocale();
  const formatPattern = getNumberPattern(pattern);
  let result;

  if (isDefined(number) && _.isString(number) && _.has(NumberFormat.locale, currentLocale)) {
    result = new NumberFormat(currentLocale, formatPattern).parse(_.trim(number));
  } else if (_.isNumber(number)) {
    return number;
  } else {
    // ignore because we can't parse without locale information
    result = NaN;
  }

  return result;
}

export function parseCurrency(number, pattern, currencySymbol) {
  const currentLocale = getCurrentLocale();
  const formatPattern = getCurrencyPattern(pattern);
  let currency = currencySymbol;

  if (isNotDefined(currency)) {
    currency = getCurrencySymbol();
  }

  let result;

  if (isDefined(number) && _.isString(number) && _.has(NumberFormat.locale, currentLocale)) {
    result = new NumberFormat(currentLocale, formatPattern, currency).parse(number);
  } else if (_.isNumber(number)) {
    return number;
  } else {
    // ignore because we can't parse without locale information
    result = NaN;
  }

  return result;
}

function getTextModulesForContext(context) {
  const i18nConfig = applicationState.cursor(['i18n']);
  const currentLocale = getCurrentLocale();
  return get(i18nConfig, [currentLocale, 'textModules', context]);
}

function setTextModulesForContext(context, contextContent) {
  const i18nConfig = applicationState.cursor(['i18n']);
  const currentLocale = getCurrentLocale();
  set(i18nConfig, [currentLocale, 'textModules', context], contextContent);
}

function getTextModuleText(context, textModules, name) {
  const content = get(textModules, name);
  if (isValueEmpty(content)) {
    logger.warn('textmodule with name', name, 'not found in context', context);
  }
  return content;
}

export function getTextModuleContent(
  name,
  orgUnitId,
  type = get(applicationState.cursor(['configuration', 'backend']), 'textModuleType')
) {
  const pattern = isValueNotEmpty(orgUnitId)
    ? '{context}/{api}/{version}/textModule/{organizationUnitId}/{type}'
    : '{context}/{api}/{version}/textModule/{type}';
  const options = {
    organizationUnitId: orgUnitId,
    type: type,
  };

  const context = url.build(pattern, options);
  const textModulesForCurrentLocale = getTextModulesForContext(context);

  let result;
  if (isDefined(textModulesForCurrentLocale)) {
    result = Promise.resolve(getTextModuleText(context, textModulesForCurrentLocale, name));
  } else {
    // check cache
    let backendCallPromise = get(textModuleRequestCache, context);

    if (isNotDefined(backendCallPromise)) {
      backendCallPromise = doRequest(context)
        .then(resolveData)
        .then((contextContent) => {
          setTextModulesForContext(context, contextContent);
        });

      backendCallPromise.finally(() => {
        set(textModuleRequestCache, context, null);
      });

      set(textModuleRequestCache, context, backendCallPromise);
    }

    result = backendCallPromise
      .then(() => {
        const textModules = getTextModulesForContext(context);
        return getTextModuleText(context, textModules, name);
      })
      .catch(() => {
        logger.warn('no textmodules found for context', context);
      });
  }
  return result;
}
