import _ from 'lodash';
import { get, isDefined, isValueNotEmpty } from 'core/util/lang';
import Immutable from 'immutable';
import { doRequest } from 'core/backend/doRequest';
import { wrapError } from 'core/util/error';
import { resolveData } from 'core/util/promises';
import RequestError from 'core/error/requestError';
import { LINK_RELS } from 'core/linkRels';

/**
 * @typedef {object} Link
 * @property {object} [rel] - relation name
 * @property {string} [href] - link destination
 */

/**
 * @typedef {object} Linkable
 * @property {Link[]} [links]
 */

/**
 * extract the link for a specific relation
 * @param {string} [rel] name of the relation to query for
 * @param {Link[] | Linkable} [links] collection of links
 * @param {string} [targetProp]  the property to extract
 * @returns {string|null}
 */
// default rel with _will_never_be_found to avoid false positives if
// caller calls with rel = undefined
export function follow(rel = '_will_never_be_found', links = [], targetProp = 'href') {
  let resolvedLinks;

  if (!_.isArray(links) && !Immutable.Iterable.isIndexed(links)) {
    resolvedLinks = get(links, 'links') || [];
  } else {
    resolvedLinks = links;
  }

  const value = get(
    resolvedLinks.find((toInspect) => get(toInspect, 'rel') === rel),
    targetProp
  );
  return value || null;
}

/**
 * test if a link with a specific relation exists
 * @param rel name of the relation to query for
 * @param links collection of links
 * @param targetProp  the property to extract
 * @returns {boolean}
 */
export function hasLink(rel, links = [], targetProp = 'href') {
  return isValueNotEmpty(follow(rel, links, targetProp));
}

/**
 * extract the link for a specific relation
 * @param rel name of the relation to query for
 * @param links collection of links
 * @param targetProp  the property to extract
 * @returns {string[]|null}
 */
// default rel with _will_never_be_found to avoid false positives if
// caller calls with rel = undefined
export function followMultiple(rel = '_will_never_be_found', links = [], targetProp = 'href') {
  let resolvedLinks;

  if (_.isArray(links) || Immutable.Iterable.isIndexed(links)) {
    resolvedLinks = links;
  } else {
    resolvedLinks = get(links, 'links') || [];
  }

  const hrefList = [];
  _.forEach(
    resolvedLinks.filter((toInspect) => get(toInspect, 'rel') === rel),
    (value) => {
      hrefList.push(get(value, targetProp));
    }
  );

  return hrefList;
}

export function followLink(linkName, propertyName) {
  return (data) => {
    if (isDefined(data)) {
      const link = follow(linkName, get(data, 'links'), propertyName);
      if (isDefined(link)) {
        return doRequest(link)
          .then(resolveData)
          .catch((err) => {
            throw wrapError(err);
          });
      } else {
        return Promise.reject(new Error(`link "${linkName}" not found`));
      }
    } else {
      return Promise.reject(new Error('no data given'));
    }
  };
}

export function fetchCreated(response) {
  const actualResponse = response.response;
  if (actualResponse.headers.has('location')) {
    return doRequest(actualResponse.headers.get('location'));
  } else {
    response.cause = 'Missing expected location header in response';
  }
  return Promise.reject(new RequestError(response));
}

export function filterEvents(links) {
  return filterByRelName(links, LINK_RELS.CONFLICTING_COURSE_EVENT);
}

export function filterCourses(links) {
  return filterByRelName(links, LINK_RELS.CONFLICTING_COURSE);
}

export function filterByRelName(overlappingLinks, relName) {
  return Promise.map(_.filter(overlappingLinks, ['rel', relName]), (link) => {
    const url = get(link, 'href');

    return doRequest(url)
      .then(resolveData)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error ', error);
      });
  });
}
