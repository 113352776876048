import _ from 'lodash';
import { get, isDefined, isValueEmpty, isValueNotEmpty } from 'core/util/lang';
import { translate } from 'core/i18n/translate';
import FetchError from 'core/error/fetchError';
import WrappedError from 'core/error/wrappedError';
import { ERROR_TYPES } from 'core/errorTypes';
import { getMessage } from 'core/validation/validate';
import { SEVERITY } from 'core/service/shell/notification';

function handleNotFoundError(err) {
  return {
    type: ERROR_TYPES.NOT_FOUND,
    response: err.response,
    data: err.data,
    message: translate('clientcore.error.notfound'),
    severity: SEVERITY.ERROR,
    error: err,
  };
}

function handleForbiddenError(err) {
  return {
    type: ERROR_TYPES.FORBIDDEN,
    response: err.response,
    data: err.data,
    message: get(err, 'data.message') || translate('clientcore.error.forbidden'),
    severity: SEVERITY.ERROR,
    error: err,
  };
}

function handleGeneralBackendError(err) {
  if (isValueNotEmpty(get(err, 'data.message'))) {
    return {
      type: ERROR_TYPES.BACKEND,
      response: err.response,
      data: err.data,
      message: get(err, 'data.message'),
      severity: SEVERITY.ERROR,
      error: err,
    };
  }
}

function handleValidationError(err) {
  if (isValueNotEmpty(get(err, 'data.globalErrors[0].message'))) {
    return {
      type: ERROR_TYPES.VALIDATION,
      response: err.response,
      data: err.data,
      message: get(err, 'data.globalErrors[0].message'),
      severity: SEVERITY.ERROR,
      error: err,
    };
  } else if (isValueNotEmpty(get(err, 'data.fieldErrors[0].message'))) {
    return {
      type: ERROR_TYPES.VALIDATION,
      response: err.response,
      data: err.data,
      message: getMessage(get(err, 'data.fieldErrors[0]')),
      severity: SEVERITY.ERROR,
      error: err,
    };
  } else if (isValueNotEmpty(get(err, 'data.error'))) {
    return {
      type: ERROR_TYPES.UNKNOWN,
      response: err.response,
      data: err.data,
      message: get(err, 'data.error'),
      severity: SEVERITY.ERROR,
      error: err,
    };
  }
}

function handleUnknownBackendError(err) {
  return {
    type: ERROR_TYPES.BACKEND,
    response: err.response,
    data: err.data,
    message: 'error',
    severity: SEVERITY.ERROR,
    error: err,
  };
}

export function handleBackendError(err, response) {
  let errorDescription;
  const status = get(response, 'status');
  if (status === 404) {
    errorDescription = handleNotFoundError(err);
  } else if (status === 403) {
    errorDescription = handleForbiddenError(err);
  }

  if (isValueEmpty(errorDescription)) {
    errorDescription = handleGeneralBackendError(err);
  }
  if (isValueEmpty(errorDescription)) {
    errorDescription = handleValidationError(err);
  }
  if (isValueEmpty(errorDescription)) {
    errorDescription = handleUnknownBackendError(err);
  }
  return errorDescription;
}

export function wrapError(err) {
  let errorDescription;

  if (err instanceof WrappedError) {
    // don't wrap again
    return err;
  }

  if (isDefined(get(err, 'response'))) {
    const response = err.response;

    if (response.status >= 400) {
      // backend errors
      errorDescription = handleBackendError(err, response);
    } else {
      errorDescription = {
        type: ERROR_TYPES.UNKNOWN,
        severity: SEVERITY.ERROR,
        message: 'error',
        error: err,
      };
    }
  } else if (_.isError(err)) {
    if (err instanceof Promise.TimeoutError) {
      errorDescription = {
        type: ERROR_TYPES.TIMEOUT,
        error: err,
        severity: SEVERITY.WARN,
        message: translate('clientcore.error.timeout'),
      };
    } else if (err instanceof FetchError) {
      errorDescription = {
        type: ERROR_TYPES.NETWORK,
        severity: SEVERITY.ERROR,
        message: translate('clientcore.error.network'),
        error: err,
      };
    } else {
      errorDescription = {
        type: ERROR_TYPES.ERROR,
        severity: SEVERITY.ERROR,
        message: err.message,
        error: err,
      };
    }
  } else {
    errorDescription = {
      type: ERROR_TYPES.UNKNOWN,
      error: err,
      severity: SEVERITY.ERROR,
      message: get(err, 'message') || _.toString(err),
    };
  }

  return new WrappedError(errorDescription);
}
