import immstruct from 'immstruct';
import _ from 'lodash';
import { set } from 'core/util/lang';

// define initial state

export const applicationStructure = immstruct('appState', {
  state: {
    i18n: {
      currentLocale: 'de_AT'
    },
    session: {
      id: null,
      user: null
    },
    configuration: {
      appName: '',
      backend: {
        updateCheckInterval: 1, // value in minutes
        heartBeatInterval: 10, // value in minutes
        contextPath: '',
        checkStatusPath: '{context}/{api}/{version}/status',
        API: {
          base: 'api',
          version: 'v1'
        }
      }
    }
  }
});

export const applicationState = applicationStructure.reference(['state']);

export function forceUpdateApplication() {
  _.defer(() => {
    set(applicationState, ['forceUpdateApplication'], _.random(true));
  });
}

// expose as global object to simplify debugging
//noinspection JSAnnotator
window.applicationState = applicationState;
