import _ from 'lodash';
import invariant from 'invariant';
import { applicationState } from 'core/state/applicationState';

const possibleLogLevels = ['error', 'warn', 'info', 'debug'];

const loggerRegistry = new Map<string, Logger>();
const logLevelErrorFormat = `unsupported log level '%s'. Supported values are %s`;

const replaceRegexp = /(%c|%d|%i|%s|%o|%f)/g;

const globalTransports = [];

function appendLog(loggerName, level, message, ...data) {
  return (transport) => {
    transport({
      logger: loggerName,
      level: level,
      message: message,
      data: data,
    });
  };
}

class Logger {
  loggerName: string;
  loggerNameAsPath: string[];
  localTransports: any[];

  constructor(loggerNameToSet, logLevel = 'info', transports = []) {
    this.loggerName = loggerNameToSet;
    this.loggerNameAsPath = this.loggerName.split('.');

    invariant(_.isArray(transports), 'transports should be an array');

    this.localTransports = transports;

    applicationState.cursor().setIn(['configuration', 'logger'].concat(this.loggerNameAsPath), logLevel);
  }

  log(level, message, ...data) {
    this.localTransports.forEach(appendLog(this.loggerName, level, message, ...data));
    globalTransports.forEach(appendLog(this.loggerName, level, message, ...data));
  }

  error(message, ...data) {
    this.log('error', message, ...data);
  }

  warn(message, ...data) {
    if (this.getLevel() !== 'error') {
      this.log('warn', message, ...data);
    }
  }

  info(message, ...data) {
    if (this.getLevel() !== 'error' && this.getLevel() !== 'warn') {
      this.log('info', message, ...data);
    }
  }

  debug(message, ...data) {
    if (this.getLevel() !== 'error' && this.getLevel() !== 'warn' && this.getLevel() !== 'info') {
      this.log('debug', message, ...data);
    }
  }

  setLevel(newLevel) {
    applicationState.cursor().setIn(['configuration', 'logger'].concat(this.loggerNameAsPath), newLevel);
  }

  getLevel() {
    return applicationState.cursor().getIn(['configuration', 'logger'].concat(this.loggerNameAsPath));
  }
}

function validateLogLevel(logLevel) {
  invariant(_.includes(possibleLogLevels, logLevel), logLevelErrorFormat, logLevel, possibleLogLevels);
}

export function substitute(message = '', data = []) {
  const messageToReplace = message;
  let counter = 0;

  return messageToReplace.replace(replaceRegexp, (placeholder) => {
    let result;

    if (placeholder === '%s') {
      result = data[counter];
    } else if (placeholder === '%c') {
      // ignore styling
      result = '';
    } else if (placeholder === '%d' || placeholder === '%i') {
      result = Number.parseInt(data[counter], 10);
    } else if (placeholder === '%f') {
      result = Number.parseFloat(data[counter]);
    } else if (placeholder === '%o') {
      try {
        result = JSON.stringify(data[counter]);
      } catch (e) {
        result = data[counter];
      }
    }
    counter = counter + 1;

    return result;
  });
}

export function registerGlobalTransport(transport) {
  globalTransports.push(transport);
}

export function setLevel(regexp, logLevel) {
  let exp;

  if (_.isRegExp(regexp)) {
    exp = regexp;
  } else if (_.isString(regexp)) {
    exp = new RegExp(regexp);
  } else {
    throw new Error('regexp must be either a regular expression or a string');
  }

  validateLogLevel(logLevel);

  loggerRegistry.forEach((loggerInstance, loggerName) => {
    if (exp.test(loggerName)) {
      loggerInstance.setLevel(logLevel);
    }
  });
}

function getLogger(loggerName, defaultLogLevel = 'info', transports = []): Logger {
  validateLogLevel(defaultLogLevel);

  let instance = loggerRegistry.get(loggerName);

  if (_.isUndefined(instance)) {
    instance = new Logger(loggerName, defaultLogLevel, transports);
    loggerRegistry.set(loggerName, instance);
  }

  return instance;
}

export default getLogger;
