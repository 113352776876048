export const LINK_RELS = {
  ALREADY_REGISTERED: 'alreadyRegistered',
  CALENDAR_EVENTS: 'calendarEvents',
  CONFLICTING_COURSE: 'conflictingCourse',
  CONFLICTING_COURSE_EVENT: 'conflictingCourseEvent',
  COURSE: 'course',
  COURSE_EVENT: 'courseEvent',
  COURSE_SERIES: 'courseSeries',
  COURSE_SERIES_CHILD_REGISTRATIONS: 'childRegistrations',
  COURSE_SERIES_PARTS: 'courseSeriesParts',
  COURSE_GUIDE_SEARCH_CONTEXT: 'courseGuideSearchContext',
  COURSE_PICTURE: 'coursePicture',
  DELETE: 'delete',
  DOWNLOAD_AT: 'downloadAt',
  PREVIEW_AT: 'previewAt',
  ELEARNING_LINK: 'elearningLink',
  ENTITIES: 'entities',
  ENTRIES: 'entries',
  EQL_FILTER_LIST: 'eqlFilterList',
  FACTORY: 'factory',
  FEEDBACK_SHEET_INSTANCE: 'feedbackSheetInstance',
  HAVING_FUTURE_EVENTS: 'havingFutureEvents',
  LAUNCH_ELEARNING_COURSE_LINK: 'launchELearningCourseLink',
  ELEARNING_LAUNCH_INFO_LINK: 'eLearningLaunchInfoLink',
  ELEARNING_REGISTRATION: 'eLearningRegistration',
  LEARNING_PATH: 'learningPath',
  LEARNING_PATH_PERSONALIZED: 'learningPathPersonalized',
  LOOKUP_QUERY: 'lookupQuery',
  LOOKUP_USERS: 'lookupUsers',
  NEW_ANSWER: 'newAnswer',
  NEXT_EVENTS: 'nextEvents',
  NEXT_EVENTS_GLOBAL: 'nextEventsGlobal',
  NEXT_EVENTS_COURSE_SERIES: 'nextEventsCourseSeries',
  OPTIONS: 'options',
  OVERLAPPINGS: 'overlappings',
  PICTURE: 'picture',
  REBOOKING: 'rebooking',
  REGISTRATION: 'registration',
  REGISTRATION_TEMPLATE: 'registrationTemplate',
  REGISTRATION_DATA: 'registrationData',
  VALIDATE_REGISTRATION: 'validateRegistration',
  SELF: 'self',
  SETTINGS: 'settings',
  STATUS_LIST: 'statusList',
  SUBMIT_FORM: 'submitForm',
  TRANSITIONS: 'transitions',
  VIEW_MODEL: 'viewModel',
  WORKFLOW_STATUS_OPTION_AUX_VALUES: 'workflowStatusOptionAuxValues',
  MEDIUM_TYPES: 'mediumTypes',
  OFFICES: 'offices',
  NUMBER_OF_MY_JOBOFFER_APPLICATIONS: 'numberOfMyJobOfferApplications',
  JOBEXCHANGE_JOB: 'jobExchangeJob',
  ONE_CLICK_APPLICATION_FILE_VIEW_MODEL: 'oneClickApplicationFileViewModel',
  SHOW_ONE_CLICK_APPLICATION_FILE: 'showOneClickApplicationFile',
  DOWNLOAD_ONE_CLICK_APPLICATION_FILE: 'downloadOneClickApplicationFile',
  CURRENT_PROFESSIONAL_CAREER_INTERNAL: 'currentProfessionalCareerInternal',
  MANAGER: 'manager',
  MANAGER_ASSIGNMENTS: 'managerAssignments',
  MY_RATING: 'myRating',
  OTHER_RATINGS: 'otherRatings',
  SPECIFIC_DATA: 'specificData',
  SOCIO_DEMOGRAPHIC_DATA: 'socioDemographicData',
  AVERAGE_RATED_STARS: 'averageRatedStars',
  DELETE_VIEW_MODEL: 'deleteViewModel',
  DELETE_DOCUMENT: 'deleteDocument',
  MOVE_DOCUMENT: 'moveDocument',
  DELETE_NOTE: 'deleteNote',
  UPDATE_NOTE: 'updateNote',
  REL_QUALIFICATION_TARGET_VALUES_FROM_JOBPROFILES: 'qualificationTargetValuesFromJobProfiles',
  REL_COMPETENCE_TARGET_VALUES_FROM_JOBPROFILES: 'competenceTargetValuesFromJobProfiles',
  AMENDMENT: 'amendment',
  AMENDMENT_WIZARD: 'amendmentWizard',
  LATEST_APPLICATION_STATUS: 'latestApplicationStatus',
  ORG_UNIT_LEGAL_ADDRESS: 'legalCompanyAddress',
  REL_JOBPROFILE_FROM_CAREER: 'jobProfileFromCareer',
  REL_JOBPROFILE_MANAGEMENTPROFILE: 'managementProfile',
  JOBPROFILE_IMAGE_TOP: 'imageTop',
  JOBPROFILE_IMAGE_MANAGEMENT_PROFILE: 'imageManagementProfile',
  JOBPROFILE_IMAGE_ACTIVITIES_AND_REQUIREMENTS: 'imageActivitiesAndRequirements',
  JOBPROFILE_COMPETENCES_RECOMMENDED: 'jobProfileCompetencesRecommended',
};
