import _ from 'lodash';
import * as csrfToken from 'core/boot/csrfTokenHandler';
import * as sessionHandler from 'core/boot/sessionHandler';
import * as cgSettingsHandler from 'core/boot/courseguideSettingsHandler';
import * as sysSettingsHandler from 'core/boot/systemSettingsHandler';
import * as heartBeatHandler from 'core/boot/heartBeatHandler';
import * as cacheKeyHandler from 'core/boot/cacheKeyHandler';
import * as localeHandler from 'core/boot/localeHandler';
import * as embeddedHandler from 'core/boot/embeddedHandler';
import * as registerLoggerBackendTransport from 'core/boot/registerLoggerBackendTransport';
import * as engageStatusHandler from 'core/boot/engageStatusHandler';
import * as doRequestSessionErrorHandler from 'core/boot/doRequestSessionErrorHandler';
import { BOOT_ORDER, ERROR_INITIALIZATION } from 'core/boot/constants';
import { applicationState } from 'core/state/applicationState';
import { set } from 'core/util/lang';

class CallbackRegistry {
  constructor() {
    this.registry = new Map();
  }

  set(phase, cb) {
    if (_.isUndefined(phase) || _.isNull(phase)) {
      throw new Error('phase must be defined');
    }
    if (!_.isFunction(cb)) {
      throw new Error('cb must be a function');
    }

    let cbList;
    if (!this.registry.has(phase)) {
      cbList = [];
      this.registry.set(phase, cbList);
    } else {
      cbList = this.registry.get(phase);
    }

    cbList.push(cb);
  }

  callCallbacks() {
    let currentPromise = Promise.resolve(null);

    _.forEach(BOOT_ORDER, state => {
      set(applicationState, 'boot.state', state);

      if (this.registry.has(state)) {
        _.forEach(this.registry.get(state), cb => {
          currentPromise = currentPromise.then(() => {
            return Promise.resolve(cb());
          });
        });
      }
    });

    return currentPromise.catch(error => {
      set(applicationState, 'boot.state', ERROR_INITIALIZATION);
      throw error;
    });
  }
}

class Initializer {
  constructor(callbackRegistry) {
    this.callbackRegistry = callbackRegistry;
  }

  callback(phase, cb) {
    this.callbackRegistry.set(phase, cb);
    return this;
  }

  boot() {
    return this.callbackRegistry.callCallbacks();
  }
}

const anonymousApp = new Initializer(new CallbackRegistry());
engageStatusHandler.register(anonymousApp);
csrfToken.register(anonymousApp);
sessionHandler.register(anonymousApp);
cgSettingsHandler.register(anonymousApp);
sysSettingsHandler.register(anonymousApp);
heartBeatHandler.register(anonymousApp);
cacheKeyHandler.register(anonymousApp);
localeHandler.register(anonymousApp);
doRequestSessionErrorHandler.register(anonymousApp);
registerLoggerBackendTransport.register(anonymousApp);

const embeddedApp = new Initializer(new CallbackRegistry());
csrfToken.register(embeddedApp);
heartBeatHandler.register(embeddedApp);
cacheKeyHandler.register(embeddedApp);
registerLoggerBackendTransport.register(embeddedApp);
doRequestSessionErrorHandler.register(embeddedApp);
embeddedHandler.register(embeddedApp);

const defaultApp = new Initializer(new CallbackRegistry());
engageStatusHandler.register(defaultApp);
csrfToken.register(defaultApp);
sessionHandler.register(defaultApp);
cgSettingsHandler.register(defaultApp);
sysSettingsHandler.register(defaultApp);
heartBeatHandler.register(defaultApp);
cacheKeyHandler.register(defaultApp);
localeHandler.register(defaultApp);
doRequestSessionErrorHandler.register(defaultApp);
registerLoggerBackendTransport.register(defaultApp);

const customApp = new Initializer(new CallbackRegistry());

class InitializerBuilder {
  anonymous() {
    return anonymousApp;
  }

  custom() {
    return customApp;
  }

  embedded() {
    return embeddedApp;
  }

  default() {
    return defaultApp;
  }
}

// expose singleton
export const initialize = new InitializerBuilder();
