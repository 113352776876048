import { AFTER_SESSION_CHECK } from 'core/boot/constants';
import { doRequest } from 'core/backend/doRequest';
import moment from 'moment';
import { applicationState } from 'core/state/applicationState';
import _ from 'lodash';

export function heartBeatHandler() {
  const backendSettings = applicationState.cursor(['configuration', 'backend']);
  let heartBeatInterval = _.toNumber(backendSettings.get('heartBeatInterval'));

  if (_.isNaN(heartBeatInterval)) {
    heartBeatInterval = 10;
  }

  if (heartBeatInterval > 0) {
    const pollInterval = moment.duration(heartBeatInterval, 'Minutes').asMilliseconds();
    // install timer
    setInterval(() => {
      // simply fire and forget
      doRequest('{context}/common/sessionHeartbeat.do', {
        method: 'POST',
        responseAs: 'TEXT' // set to TEXT because we don't get any response! so no parsing should be done
      });
    }, pollInterval);
  }
}

export function register(initializer) {
  initializer.callback(AFTER_SESSION_CHECK, heartBeatHandler);
}
